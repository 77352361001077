<template>
  <NotificationsWrapperStyled>
    <VPopover :placement="'bottom-start'" :open.sync="isActive" :popperOptions="popperOptions">
      <NotificationsTriggerStyled :class="{ active: isActive }">
        <BellIcon />
      </NotificationsTriggerStyled>
      <template v-slot:popover>
        <NotificationsStyled>
          <div>{{ $t('messages.noNotifications') }}</div>
        </NotificationsStyled>
      </template>
    </VPopover>
  </NotificationsWrapperStyled>
</template>

<script>
import { styled } from '@egoist/vue-emotion'
import chroma from 'chroma-js'
import { VPopover } from 'v-tooltip'
import { BellIcon } from 'vue-feather-icons'

import { flexCenter, buttonReset } from '@styles/mixins'

const NotificationsWrapperStyled = styled('div')`
  grid-area: notifications;
  width: 100%;
  height: 100%;
  .v-popover,
  .trigger {
    width: 100%;
    height: 100%;
  }
  button {
    outline: none !important;
  }
`

const NotificationsTriggerStyled = styled('button')`
  ${flexCenter}
  ${p => buttonReset(p)}
  width: 100%;
  height: 100%;
  outline: none;
  backdrop-filter: blur(7px);
  background: ${props => chroma(props.theme.colors.midnightBlue).darken(1.0).alpha(0.5).css()};
  &:hover,
  &.active {
    background: ${props => chroma(props.theme.colors.midnightBlue).darken(1.0).alpha(0.8).css()};
  }
`

const NotificationsStyled = styled('div')`
  ${flexCenter}
  width: 300px;
  box-sizing: border-box;
  padding: 1rem;
  backdrop-filter: blur(7px);
  background: ${props => chroma(props.theme.colors.midnightBlue).darken(1.0).alpha(0.5).css()};
`

export default {
  components: {
    NotificationsWrapperStyled,
    NotificationsTriggerStyled,
    NotificationsStyled,
    BellIcon,
    VPopover,
  },
  data() {
    return {
      isActive: false,
    }
  },
  computed: {
    popperOptions() {
      return {
        modifiers: {
          preventOverflow: {
            escapeWithReference: true,
          },
        },
      }
    },
  },
}
</script>
