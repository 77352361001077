<template>
  <InputAtom
    type="text"
    :class="{ 'id-like': isIdLike }"
    :value="value"
    :readonly="isReadonly"
    @input="$emit('update:value', $event.target.value)"
  />
</template>

<script>
import { styled } from '@egoist/vue-emotion'

const InputAtom = styled('input')`
  background: none;
  border: none;
  outline: none;
  height: 2.5rem;
  line-height: 2.5rem;
  color: ${({ theme }) => theme.colors.white};
  &.id-like {
    font-family: monospace;
    font-size: 1rem;
    height: 2.2rem;
    line-height: 2.2rem;
    padding-top: 0.3rem;
  }
`

export default {
  props: {
    value: {
      type: String,
    },
    isReadonly: {
      type: Boolean,
      default: false,
    },
    isIdLike: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    InputAtom,
  },
}
</script>
