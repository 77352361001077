import convert from '@common/convert-units'
import round from './round'
import numberLocalized from './number-localized'

function conv(value, si, ui, r = 2, attachUnit = true) {
  // Keep this implemented here due to significant digits after 0
  if (si === 'l/kg m' && ui === 'l/t km') {
    if (attachUnit) {
      return round(value * (1000 * 1000)) + ` ${ui}`
    }
    return round(value * (1000 * 1000))
  }
  // Keep this implemented here due to significant digits after 0
  if (si === 'l/kg m' && ui === 'gal/t mi') {
    if (attachUnit) {
      return round(value * 3.785 * (1000 * 1609)) + ` ${ui}`
    }
    return round(value * 3.785 * (1000 * 1609))
  }
  // Needed since 1/s is not supported by convert-units library
  if (si === '1/s' && ui === '1/h') {
    if (attachUnit) {
      return round(value * 3600) + ` ${ui}`
    }
    return round(value * 3600)
  }
  // Needed since kg/s is not supported by convert-units library
  if (si === 'kg/s' && (ui === 'mt/h' || ui === 't/h')) {
    const factor = ui === 'mt/h' ? 1000 : 907.18474
    const convertedValue = (value * 3600) / factor

    if (attachUnit) {
      return round(convertedValue) + ` ${ui}`
    }
    return round(convertedValue)
  }
  if (si && ui && si !== ui) {
    if (attachUnit) {
      return round(convert(value).from(si).to(ui), r) + ` ${ui}`
    }
    return round(convert(value).from(si).to(ui), r)
  }
  if (ui) {
    if (attachUnit) {
      return round(value, r) + ` ${ui}`
    }
    return round(value, r)
  }
  return round(value, r)
}

export default function (value, si, ui, r = 2, attachUnit = true, format = false, thousandsSeperator = '.', decimalSeperator = ',') {
  if (!format) {
    return conv(value, si, ui, r, attachUnit)
  }
  const s = numberLocalized(conv(value, si, ui, r, false), thousandsSeperator, decimalSeperator)
  if (attachUnit) {
    return `${s} ${ui ? ui.replace('mt', 't') : ''}`
  }
  return s
}

export function convertToLocalUnitSystem(selectedUIUnitSystem, unitMetric, unitImperial) {
  switch (selectedUIUnitSystem) {
    case 'METRIC':
      return unitMetric
    case 'IMPERIAL':
      return unitImperial
  }
}

export function resolveUnitUI(selectedUIUnitSystem, dimesnion) {
  const conversions = {
    mass: {
      METRIC: 'mt',
      IMPERIAL: 't',
    },
    length: {
      METRIC: 'km',
      IMPERIAL: 'mi',
    },
    time: {
      METRIC: 'h',
      IMPERIAL: 'h',
    },
    minutes: {
      METRIC: 'min',
      IMPERIAL: 'min',
    },
    massVelocity: {
      METRIC: 'mt/km',
      IMPERIAL: 't/mi',
    },
    volumeMass: {
      METRIC: 'l/mt',
      IMPERIAL: 'gal/t',
    },
    volumeFlowRates: {
      METRIC: 'l/h',
      IMPERIAL: 'gal/h',
    },
    meters: {
      METRIC: 'm',
      IMPERIAL: 'ft',
    },
    fullTime: {
      METRIC: 'HH:MM:SS',
      IMPERIAL: 'HH:MM:SS',
    },
    massPerTime: {
      METRIC: 'mt/h',
      IMPERIAL: 't/h',
    },
  }
  return conversions[dimesnion][selectedUIUnitSystem]
}

/**
 * At the moment only used for cycle table
 */
export function resolveDimensionUnit(dimension) {
  const units = {
    sum_distance: {
      physicalUnitSI: 'm',
      METRIC: 'km',
      IMPERIAL: 'mi',
    },
    duration: {
      physicalUnitSI: 's',
      METRIC: 'min',
      IMPERIAL: 'min',
    },
    tonnage: {
      physicalUnitSI: 'kg',
      METRIC: 'mt',
      IMPERIAL: 't',
    },
  }

  return units[dimension]
}
