<template>
  <SubNavigationItemStyled>
    <router-link :to="{ name: routeName }">
      <span> {{ $t(`navigation.archon.${routeName}`) }} </span>
    </router-link>
  </SubNavigationItemStyled>
</template>

<script>
import { styled } from '@egoist/vue-emotion'
import chroma from 'chroma-js'

const SubNavigationItemStyled = styled('div')`
  > a {
    display: block;
    font-size: 0.85rem;
    color: ${props => props.theme.colors.white};
    border-right: 2px solid transparent;
    padding: 0.5rem;
    border-top-left-radius: 0.5rem;
    border-bottom-left-radius: 0.5rem;
    &:hover {
      color: ${props => props.theme.colors.primary};
    }

    &.router-link-active {
      color: ${props => props.theme.colors.primary};
      border-right: 2px solid ${props => props.theme.colors.primary};
      background: ${p => chroma(p.theme.colors.midnightBlue).brighten(1).alpha(0.5).css()};
    }
  }
`

export default {
  props: {
    navigationItem: {
      type: Object,
    },
  },
  components: {
    SubNavigationItemStyled,
  },
  computed: {
    routeName() {
      return this.navigationItem.name || this.navigationItem.meta?.name
    },
  },
}
</script>
