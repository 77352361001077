import { DateTime } from 'luxon'

export function timeAgo(isoTime, daysLimit = -1) {
  if (!isoTime) {
    return ''
  }
  const now = DateTime.local()
  const t = DateTime.fromISO(isoTime)
  const diff = now.diff(t)
  const diffInDays = diff.as('days')
  if (daysLimit > -1 && diffInDays >= daysLimit) {
    return t.toLocaleString(DateTime.DATETIME_MED)
  }
  if (diffInDays >= 1) {
    return `${Math.round(diffInDays)} days ago`
  } else {
    const diffInHours = diff.as('hours')
    if (diffInHours < 1) {
      const diffInMinutes = diff.as('minutes')
      if (diffInMinutes < 1) {
        const diffInSeconds = diff.as('seconds')
        return `${Math.round(diffInSeconds)} seconds ago`
      }
      return `${Math.round(diffInMinutes)} minutes ago`
    }
    return `${Math.round(diffInHours)} hours ago`
  }
}
