<template>
  <MainNavigationItemStyled>
    <router-link :to="to" :exact="navigationItem.name === 'home'" :class="{ 'active-by-path': activeByPath }">
      <template>
        <HomeIcon v-if="navigationItem.name === 'home'" />
        <UsersIcon v-else-if="navigationItem.name === 'users'" />
        <TalpaIcon v-else-if="navigationItem.name === 'assets'" class="icon" :scope="'Misc'" :name="'FleetIcon'" />
        <HardDriveIcon v-else-if="navigationItem.name === 'loggers'" />
        <ColumnsIcon v-else-if="navigationItem.name === 'assetDimensions'" />
        <BriefcaseIcon v-else-if="navigationItem.name === 'organizations'" />
        <MapIcon v-else-if="navigationItem.name === 'sites'" />
        <TrendingUpIcon v-else-if="navigationItem.name === 'dashboards'" />
        <CalendarIcon v-else-if="navigationItem.name === 'calendars'" />
        <ZapIcon v-else-if="navigationItem.name === 'events'" />
        <ShoppingBagIcon v-else-if="navigationItem.name === 'Marketplace'" />
      </template>
      <span> {{ $t(`navigation.archon.${navigationItem.name}`) }} </span>
    </router-link>
    <SubNavigationItemsStyled v-if="subNavigationItems.length > 0">
      <SubNavigationItem v-for="(subNavigationItem, i) in subNavigationItems" :key="i" :navigationItem="subNavigationItem" />
    </SubNavigationItemsStyled>
  </MainNavigationItemStyled>
</template>

<script>
import { styled } from '@egoist/vue-emotion'
import chroma from 'chroma-js'

import {
  HomeIcon,
  UsersIcon,
  ColumnsIcon,
  BriefcaseIcon,
  MapIcon,
  TrendingUpIcon,
  CalendarIcon,
  HardDriveIcon,
  ZapIcon,
  ShoppingBagIcon,
} from 'vue-feather-icons'

import { TalpaIcon } from '@common/components'
import SubNavigationItem from './SubNavigationItem.vue'

const MainNavigationItemStyled = styled('div')`
  > a {
    position: relative;
    display: flex;
    align-items: center;
    margin-top: 1rem;
    margin-left: 0.5rem;
    outline: none;
    @media (min-width: 768px) {
      margin-left: 1rem;
    }
    color: #e3ecfa;
    padding: 0.5rem;
    text-decoration: none;
    border-top-left-radius: 0.5rem;
    border-bottom-left-radius: 0.5rem;

    span {
      display: none;
      @media (min-width: 768px) {
        display: inline;
      }
      flex-grow: 1;
    }

    .count {
      position: absolute;
      right: 0.2rem;
      bottom: 0rem;
      transform: scale(0.7);
      @media (min-width: 768px) {
        position: static;
        transform: none;
      }
    }

    .feather,
    .icon,
    .icon svg {
      width: 16px;
      height: 16px;
      margin-right: 0.5rem;
    }

    &:hover {
      color: ${props => props.theme.colors.primary};
      background: ${p => chroma(p.theme.colors.midnightBlue).brighten(1).alpha(0.5).css()};
    }

    &.router-link-active,
    &.active-by-path {
      color: ${props => props.theme.colors.primary};
      background: ${p => chroma(p.theme.colors.midnightBlue).brighten(1).alpha(0.5).css()};
      border-right: 3px solid ${p => chroma(p.theme.colors.archonBlue).css()};
    }
  }
`

const SubNavigationItemsStyled = styled('div')`
  margin-top: 0.5rem;
  grid-template-columns: 1fr;
  grid-auto-rows: 2.5rem;
  padding-left: 2.5rem;
  align-items: center;
  display: none;
  @media (min-width: 768px) {
    display: grid;
  }
`

export default {
  props: {
    navigationItem: {
      type: Object,
      required: true,
    },
  },
  components: {
    MainNavigationItemStyled,
    SubNavigationItemsStyled,
    SubNavigationItem,
    HomeIcon,
    UsersIcon,
    ColumnsIcon,
    TalpaIcon,
    BriefcaseIcon,
    MapIcon,
    TrendingUpIcon,
    CalendarIcon,
    ZapIcon,
    HardDriveIcon,
    ShoppingBagIcon,
  },
  computed: {
    subNavigationItems() {
      return this.navigationItem.children?.filter(f => f.meta?.isNavigationItem) || []
    },
    activeByPath() {
      return this.$route?.path.includes(this.navigationItem?.path) && this.navigationItem?.path !== '/'
    },
    to() {
      const name = this.subNavigationItems.length > 0 ? this.subNavigationItems[0].name : this.navigationItem.name
      return {
        name,
      }
    },
  },
}
</script>
