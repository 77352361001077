var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('HeaderStyled', [_c('div', {
    staticClass: "title"
  }, [_vm._v(" " + _vm._s(_vm.title) + " ")]), _c('OpenSearchButtonAtom', {
    on: {
      "open-search": function openSearch($event) {
        return _vm.$root.$emit('activateOverlay', 'AdvancedSearchOverlay');
      },
      "close-search": function closeSearch($event) {
        return _vm.$root.$emit('closeOverlay');
      }
    }
  }), _c('ErrorInspector')], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }