<template>
  <EditableFieldMolecule>
    <LabelAtom class="label" :label="label" :isMuted="isReadonly" />
    <slot v-if="hasDefaultSlot" />
    <InputAtom
      v-else
      :value="isDateTime ? formatDateTime(value) : value"
      :isReadonly="isReadonly"
      :isIdLike="isIdLike"
      @update:value="$emit('update:value', $event)"
    />
    <CopyToClipboardAtom v-if="hasCopyToClipboard" :value="value" />
  </EditableFieldMolecule>
</template>

<script>
import { styled } from '@egoist/vue-emotion'

import { CopyToClipboardAtom } from '@common/components'
import { FlashMessages } from '@common/singletons'

import LabelAtom from '@/components/Atomic/Atoms/LabelAtom'
import InputAtom from '@/components/Atomic/Atoms/InputAtom'
import { DateTime } from 'luxon'

const EditableFieldMolecule = styled('div')`
  display: grid;
  grid-template-columns: min-content 1fr min-content;
  grid-gap: 0.5rem;
  align-items: center;
  border: 1px solid ${({ theme }) => theme.colors.archonBlackTransparent};
  background: ${({ theme }) => theme.colors.archonBlackTransparent};
  border-radius: 0.5rem;
  overflow: hidden;
  > .label {
    margin: 0.25rem;
  }
  > input {
  }
  .feather-copy {
    cursor: pointer;
    user-select: none;
    margin-right: 0.5rem;
    &:hover {
      color: ${({ theme }) => theme.colors.primary};
    }
  }
`

export default {
  props: {
    label: {
      type: String,
      required: true,
    },
    value: {
      type: String,
    },
    isReadonly: {
      type: Boolean,
      default: false,
    },
    isIdLike: {
      type: Boolean,
      default: false,
    },
    isDateTime: {
      type: Boolean,
      default: false,
    },
    hasCopyToClipboard: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    EditableFieldMolecule,
    LabelAtom,
    InputAtom,
    CopyToClipboardAtom,
  },
  computed: {
    hasDefaultSlot() {
      return this.$slots['default']
    },
  },
  methods: {
    async copyToClipboard(text) {
      await navigator.clipboard.writeText(text)
      FlashMessages.$emit('success', `Copied to clipboard.`, { timeout: 1000 })
    },
    formatDateTime(value) {
      return DateTime.fromISO(value).toLocaleString(DateTime.DATETIME_FULL_WITH_SECONDS)
    },
  },
}
</script>
