<template>
  <SectionTitleAtom :class="{ 'is-muted': isMuted }">
    {{ label }}
  </SectionTitleAtom>
</template>

<script>
import { styled } from '@egoist/vue-emotion'
import { flexCenter } from '@styles/mixins'

const SectionTitleAtom = styled('div')`
  ${flexCenter}
  white-space: nowrap;
  padding: 0.25rem 1rem;
  color: ${p => p.theme.colors.archonBlue};
  height: 2rem;
  border-radius: 3px;
  background: ${({ theme }) => theme.colors.panelBorder};
  &.is-muted {
    color: ${p => p.theme.colors.white};
  }
`

export default {
  props: {
    label: {
      type: String,
      required: true,
    },
    isMuted: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    SectionTitleAtom,
  },
}
</script>
