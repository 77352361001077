<template>
  <BrandStyled>
    <ArchonIcon class="brand" />
  </BrandStyled>
</template>

<script>
import ArchonIcon from '@/assets/archon_logo.svg'
import { styled } from '@egoist/vue-emotion'
import { flexCenter } from '@styles/mixins'

import chroma from 'chroma-js'

const BrandStyled = styled('div')`
  ${flexCenter}
  grid-area: brand;
  backdrop-filter: blur(7px);
  background: ${props => chroma(props.theme.colors.midnightBlue).darken(1.0).alpha(0.5).css()};
  .brand {
    max-width: 100px;
  }
`

export default {
  components: {
    BrandStyled,
    ArchonIcon,
  },
}
</script>
