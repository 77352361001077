import get from 'lodash/get'
import units from '@/utils/units'
import localizeNumber from '@/utils/number-localized'

const localesMixin = {
  inject: ['uiSettings'],
  filters: {
    units,
    localizeNumber,
  },
  computed: {
    selectedUIUnitSystem() {
      return get(this.uiSettings, 'units', 'METRIC')
    },
    thousandsSeperator() {
      return get(this.uiSettings, 'units', 'METRIC') === 'METRIC' ? '.' : ','
    },
    decimalSeperator() {
      return get(this.uiSettings, 'units', 'METRIC') === 'METRIC' ? ',' : '.'
    },
  },
  methods: {
    convUnit(value, unitSI, unitUI, r, attachUnit, format) {
      return units(value, unitSI, unitUI, r, attachUnit, format, this.thousandsSeperator, this.decimalSeperator)
    },
    numberLocalized(value) {
      return localizeNumber(value, this.thousandsSeperator, this.decimalSeperator)
    },
  },
}

export default localesMixin
