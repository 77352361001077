<template>
  <HeaderStyled>
    <div class="title">
      {{ title }}
    </div>
    <OpenSearchButtonAtom
      @open-search="$root.$emit('activateOverlay', 'AdvancedSearchOverlay')"
      @close-search="$root.$emit('closeOverlay')"
    />
    <ErrorInspector />
  </HeaderStyled>
</template>

<script>
import { styled } from '@egoist/vue-emotion'

import chroma from 'chroma-js'
import { OpenSearchButtonAtom } from '@common/components'
import ErrorInspector from '../../../../cockpit/src/components/Misc/ErrorInspector'

const HeaderStyled = styled('header')`
  display: grid;
  grid-template-columns: max-content 1fr;
  grid-template-rows: 1fr;
  grid-gap: 0.5rem;
  align-items: center;
  padding: 0.5rem 1rem;
  font-size: 14px;
  backdrop-filter: blur(7px);
  background: ${props => chroma(props.theme.colors.midnightBlue).darken(1.0).alpha(0.5).css()};
`

export default {
  components: {
    HeaderStyled,
    OpenSearchButtonAtom,
    ErrorInspector,
  },
  data() {
    return {
      headerComponent: null,
    }
  },
  computed: {
    title() {
      if (this.$route.meta?.translationKey) {
        return this.$t(`navigation.archon.${this.$route.meta.translationKey}`)
      }
      const routeName = this.$route.name
      if (!routeName) {
        return ''
      }
      return this.$t(`navigation.archon.${routeName}`)
    },
  },
}
</script>
