<template>
  <LoadingOverlayStyled>
    <LoadingDots />
  </LoadingOverlayStyled>
</template>

<script>
import { styled } from '@egoist/vue-emotion'
import { LoadingDots } from '@common/components'

const LoadingOverlayStyled = styled('div')``

export default {
  components: {
    LoadingOverlayStyled,
    LoadingDots,
  },
}
</script>
