<template>
  <ProfileWrapperStyled>
    <VPopover :placement="'bottom-start'" :open.sync="isActive" :popperOptions="popperOptions">
      <ProfileTriggerStyled :class="{ active: isActive }">
        <PictureStyled>
          <transition name="fade">
            <img class="profile-picture" v-if="!!profilePictureURL" :src="profilePictureURL" />
            <UserIcon v-else />
          </transition>
        </PictureStyled>
        <div class="details">
          <span>{{ username }}</span>
          <small>{{ email }}</small>
        </div>
      </ProfileTriggerStyled>
      <template v-slot:popover>
        <ProfileStyled>
          <ButtonStyled @click="redirectToAccountManagementConsole">{{ $tc('myAccount', 1) }}</ButtonStyled>
        </ProfileStyled>
        <ProfileStyled v-if="isTalpaAdmin">
          <ButtonStyled @click="activateTokenOverlay">API Token</ButtonStyled>
        </ProfileStyled>
        <ProfileStyled>
          <ButtonStyled @click="signOut">{{ $t('actions.logout') }}</ButtonStyled>
        </ProfileStyled>
      </template>
    </VPopover>
  </ProfileWrapperStyled>
</template>

<script>
import { styled } from '@egoist/vue-emotion'
import chroma from 'chroma-js'
import get from 'lodash/get'

import { VPopover } from 'v-tooltip'

import { flexCenter, buttonReset } from '@styles/mixins'

import { UserIcon } from 'vue-feather-icons'

import { getEmailFromToken, getUsernameFromToken, hasAnyTalpaRoleInToken } from '@common/utils'

import PROFILE_QUERY from '#/graphql/profile/show.gql'

const ProfileWrapperStyled = styled('div')`
  grid-area: profile;
  backdrop-filter: blur(7px);
  background: ${props => chroma(props.theme.colors.midnightBlue).darken(1.0).alpha(0.5).css()};
  width: 100%;
  height: 100%;
  .v-popover,
  .trigger {
    width: 100%;
    height: 100%;
  }
`

const ProfileTriggerStyled = styled('div')`
  ${flexCenter}
  ${p => buttonReset(p)}
  width: 100%;
  height: 100%;
  &:hover,
  &.active {
  }
  .feather {
    color: ${p => p.theme.colors.white};
  }
  .details {
    display: none;
    @media (min-width: 768px) {
      display: flex;
    }
    flex-direction: column;
    font-size: 14px;
    small {
      font-size: 8px;
    }
  }
`

const PictureStyled = styled('div')`
  width: 25px;
  height: 25px;
  border-radius: 50%;
  @media (min-width: 768px) {
    margin-right: 0.5rem;
  }
  background: ${props => chroma(props.theme.colors.white).alpha(0.2).css()};
  overflow: hidden;
  img {
    object-fit: cover;
    width: 100%;
    height: 100%;
  }
`

const ProfileStyled = styled('div')`
  width: 170px;
  box-sizing: border-box;
  padding: 0.5rem 1rem;
  backdrop-filter: blur(7px);
  background: ${props => chroma(props.theme.colors.midnightBlue).darken(1.0).alpha(0.5).css()};
  > div {
    display: flex;
    align-items: center;
  }
`

const ButtonStyled = styled('div')`
  ${props => buttonReset(props)}
`

export default {
  components: {
    ProfileWrapperStyled,
    VPopover,
    ProfileTriggerStyled,
    ProfileStyled,
    PictureStyled,
    UserIcon,
    ButtonStyled,
  },
  computed: {
    isTalpaAdmin() {
      return hasAnyTalpaRoleInToken(this.$keycloak.token)
    },
    username() {
      return getUsernameFromToken(this.$keycloak.token)
    },
    email() {
      return getEmailFromToken(this.$keycloak.token)
    },
    profilePictureURL() {
      return get(this.profile, 'picture.url')
    },
    popperOptions() {
      return {
        modifiers: {
          preventOverflow: {
            escapeWithReference: true,
          },
        },
      }
    },
  },
  data() {
    return {
      isActive: false,
      profile: {},
    }
  },
  methods: {
    async signOut() {
      this.$root.$emit('logout')
    },
    activateTokenOverlay() {
      this.$root.$emit('activateOverlay', 'TokenOverlay')
      this.isActive = false
    },
    activateProfileSettingsOverlay() {
      this.$root.$emit('activateOverlay', 'ProfileSettingsOverlay')
      this.isActive = false
    },
    redirectToAccountManagementConsole() {
      this.$root.$emit('redirectToAccountManagementConsole')
    },
  },
  apollo: {
    profile: {
      query: PROFILE_QUERY,
    },
  },
}
</script>
