var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('EditableFieldMolecule', [_c('LabelAtom', {
    staticClass: "label",
    attrs: {
      "label": _vm.label,
      "isMuted": _vm.isReadonly
    }
  }), _vm.hasDefaultSlot ? _vm._t("default") : _c('InputAtom', {
    attrs: {
      "value": _vm.isDateTime ? _vm.formatDateTime(_vm.value) : _vm.value,
      "isReadonly": _vm.isReadonly,
      "isIdLike": _vm.isIdLike
    },
    on: {
      "update:value": function updateValue($event) {
        return _vm.$emit('update:value', $event);
      }
    }
  }), _vm.hasCopyToClipboard ? _c('CopyToClipboardAtom', {
    attrs: {
      "value": _vm.value
    }
  }) : _vm._e()], 2);
}
var staticRenderFns = []

export { render, staticRenderFns }