<template>
  <AppStyled>
    <GlobalStyle />
    <AppHeader>
      <Brand />
      <ButtonStyled @click="signOut">{{ $t('actions.logout') }}</ButtonStyled>
    </AppHeader>
    <UnauthorizedMessage>
      {{ $t('messages.unauthorized') }}
    </UnauthorizedMessage>
  </AppStyled>
</template>

<script>
import { styled, createGlobalStyle } from '@egoist/vue-emotion'
import { BaseColors } from '@styles/themes'
import chroma from 'chroma-js'
import get from 'lodash/get'
import Brand from '@/components/App/Brand'
import { buttonReset } from '@styles/mixins'
import { SSOMixin } from '@common/mixins'
import PROFILE_QUERY from '#/graphql/profile/show.gql'

const colors = {
  ...BaseColors,
  muted: BaseColors.mediumGrey,
  primary: BaseColors.archonBlue,
  primaryLighter: chroma(BaseColors.archonBlue).brighten(1.5).css(),
  primaryDarker: chroma(BaseColors.archonBlue).darken(0.5).css(),
}

const GlobalStyle = createGlobalStyle`
  body {
    margin: 0;
    color: ${colors.white};
    font-family: 'Open Sans', sans-serif;
    background: black;
  }

  body, html {
    height: 100%;
  }
`

const AppStyled = styled('div')`
  height: 100%;
`

const ButtonStyled = styled('div')`
  ${props => buttonReset(props)}
`

const AppHeader = styled('div')`
  padding: 10px 30px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 30px;
`

const UnauthorizedMessage = styled('div')`
  padding: 0 30px;
`

export default {
  mixins: [SSOMixin],
  components: {
    AppStyled,
    GlobalStyle,
    AppHeader,
    UnauthorizedMessage,
    Brand,
    ButtonStyled,
  },
  provide() {
    const theme = {}
    Object.defineProperty(theme, 'colors', {
      enumerable: true,
      get: () => {
        return colors
      },
    })
    const uiSettings = {}
    Object.defineProperty(uiSettings, 'language', {
      enumerable: true,
      get: () => {
        return get(this.profile, 'uiSettings.language')
      },
    })
    return {
      theme,
      uiSettings,
    }
  },
  methods: {
    async signOut() {
      this.$root.$emit('logout')
    },
  },
  watch: {
    profile: {
      handler(profile) {
        const lang = get(profile, 'uiSettings.language', 'EN').toLowerCase()
        this.$i18n.locale = lang
      },
      immediate: true,
    },
  },
  apollo: {
    profile: {
      query: PROFILE_QUERY,
    },
  },
}
</script>
