<template>
  <ValidationErrorAtom>
    {{ $t(`validationErrors.${validationError.type}`, validationError) }}
  </ValidationErrorAtom>
</template>

<script>
import { styled } from '@egoist/vue-emotion'

const ValidationErrorAtom = styled('div')``

export default {
  props: {
    validationError: {
      type: Object,
      required: true,
    },
  },
  components: {
    ValidationErrorAtom,
  },
}
</script>
