var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('NotificationsWrapperStyled', [_c('VPopover', {
    attrs: {
      "placement": 'bottom-start',
      "open": _vm.isActive,
      "popperOptions": _vm.popperOptions
    },
    on: {
      "update:open": function updateOpen($event) {
        _vm.isActive = $event;
      }
    },
    scopedSlots: _vm._u([{
      key: "popover",
      fn: function fn() {
        return [_c('NotificationsStyled', [_c('div', [_vm._v(_vm._s(_vm.$t('messages.noNotifications')))])])];
      },
      proxy: true
    }])
  }, [_c('NotificationsTriggerStyled', {
    class: {
      active: _vm.isActive
    }
  }, [_c('BellIcon')], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }