<template>
  <AppStyled id="app">
    <GlobalStyle />
    <Brand />
    <router-view name="header" />
    <Settings :isActive="overlayType === 'SettingsOverlay'" @activateOverlay="activateOverlay" />
    <Notifications />
    <Profile />
    <Navigation />
    <MainStyled id="app-main" ref="main">
      <router-view name="main" :hasScrolled="mainHasScrolled" />
      <FlashMessagesStyled>
        <flash-message transitionName="flash" />
      </FlashMessagesStyled>
    </MainStyled>
    <Overlay :type="overlayType" :args="overlayArgs" :onClose="overlayOnClose" @closeOverlay="closeOverlay" />
  </AppStyled>
</template>

<script>
import { styled, createGlobalStyle } from '@egoist/vue-emotion'
import chroma from 'chroma-js'
import get from 'lodash/get'

import { BaseColors } from '@styles/themes'

import PROFILE_QUERY from '#/graphql/profile/show.gql'

import Brand from '@/components/App/Brand'
import Settings from '@/components/App/Settings'
import Notifications from '@/components/App/Notifications'
import Profile from '@/components/App/Profile'
import Navigation from '@/components/App/Navigation'
import Overlay from '@/components/App/Overlay'

import { TooltipStyles, TransitionStyles, MultiselectStyles, ScrollStyles } from '@styles/misc'
import { flexCenter } from '@styles/mixins'

import { SSOMixin, ScrolledMixin, OverlayMixin, FlashMessagesMixin } from '@common/mixins'

const colors = {
  ...BaseColors,
  muted: BaseColors.mediumGrey,
  primary: BaseColors.archonBlue,
  primaryLighter: chroma(BaseColors.archonBlue).brighten(1.5).css(),
  primaryDarker: chroma(BaseColors.archonBlue).darken(0.5).css(),
  tableBg: '#141414',
}

const GlobalStyle = createGlobalStyle`
  body {
    margin: 0;
    color: ${colors.white};
    font-family: 'Open Sans', sans-serif;
    background: url('/19609.jpg') no-repeat;
    background-size: cover;

    a {
      color: ${colors.primary};
      text-decoration: none;

      &:hover, &:focus, &.router-link-exact-active {
        color: ${colors.white};
      }

      &.btn {
        ${flexCenter}
        display: inline-flex;
        cursor: pointer;
        user-select: none;
        outline: none;
        border: none;
        background: none;
        color: ${colors.white};
        background: ${colors.tableBg};
        text-decoration: none;
        padding: 0.5rem;
        border-radius: 0.5rem;

        &:hover, &:focus, &.router-link-exact-active {
          background: ${colors.primary};
        } 
      }
    }
  }
  body, html {
    width: 100vw;
    height: 100vh;


    div, section, main {
    
        scroll-behavior: smooth;
    }
  }
  ${ScrollStyles({ theme: { colors } })}
  ${TooltipStyles({ theme: { colors } })}
  ${TransitionStyles()}
  ${MultiselectStyles({ theme: { colors } })}
  .tooltip .tooltip-inner {
    background: transparent;
  }
`

const AppStyled = styled('div')`
  height: 100vh;
  max-height: 100vh;
  display: grid;
  grid-template-columns: 50px auto 50px 50px 50px;
  grid-template-rows: 50px 1fr;
  grid-gap: 2px;
  grid-template-areas:
    'brand header settings notifications profile'
    'navigation main main main main';
  @media (min-width: 768px) {
    grid-template-columns: 200px auto 50px 50px 170px;
  }
  div,
  section,
  main {
    ${ScrollStyles}
  }
  > header {
    grid-area: header;
  }
`

const MainStyled = styled('main')`
  grid-area: main;
  width: 100%;
  height: 100%;
  grid-column: span 4;
  overflow: auto;
`

const FlashMessagesStyled = styled('div')`
  position: fixed;
  display: flex;
  justify-content: center;
  z-index: 100000;
  top: calc(50px + 1rem);
  left: calc(200px + 1rem);
  max-height: 400px;
  width: calc(100% - 200px - 4rem);
  .flash__message {
    max-width: 800px;
    display: flex;
    justify-content: space-between;
    padding: 0.5rem;
    background: ${({ theme }) => chroma(theme.colors.white).alpha(0.1).css()};
    border: 1px solid ${({ theme }) => chroma(theme.colors.white).alpha(0.1).css()};
    border-radius: 3px;
    margin-bottom: 0.5rem;

    &.error {
      background: ${({ theme }) => chroma(theme.colors.red).alpha(0.7).css()};
    }

    &.success {
      background: ${({ theme }) => chroma(theme.colors.green).alpha(0.7).css()};
    }

    button {
      cursor: pointer;
      width: 20px;
      height: 20px;
      margin-left: 0.5rem;
      color: ${({ theme }) => theme.colors.white};
      background: ${({ theme }) => chroma(theme.colors.white).alpha(0.25).css()};
      border: none;
      outline: none;

      &:hover {
        background: ${({ theme }) => chroma(theme.colors.black).alpha(0.25).css()};
      }
    }
  }
`

export default {
  mixins: [SSOMixin, ScrolledMixin, OverlayMixin, FlashMessagesMixin],
  components: {
    GlobalStyle,
    AppStyled,
    Brand,
    Settings,
    Notifications,
    Profile,
    Navigation,
    Overlay,
    MainStyled,
    FlashMessagesStyled,
  },
  provide() {
    const theme = {}
    Object.defineProperty(theme, 'colors', {
      enumerable: true,
      get: () => {
        return colors
      },
    })
    const uiSettings = {}
    Object.defineProperty(uiSettings, 'language', {
      enumerable: true,
      get: () => {
        return get(this.profile, 'uiSettings.language')
      },
    })
    Object.defineProperty(uiSettings, 'dates', {
      enumerable: true,
      get: () => {
        return get(this.profile, 'uiSettings.dates')
      },
    })
    Object.defineProperty(uiSettings, 'units', {
      enumerable: true,
      get: () => {
        return get(this.profile, 'uiSettings.units')
      },
    })
    Object.defineProperty(uiSettings, 'time', {
      enumerable: true,
      get: () => {
        return get(this.profile, 'uiSettings.time')
      },
    })
    return {
      theme,
      uiSettings,
    }
  },
  metaInfo() {
    return {
      title: 'Archon',
    }
  },
  data() {
    return {
      errors: [],
      showErrorDetails: false,
    }
  },
  watch: {
    profile: {
      handler(profile) {
        const lang = get(profile, 'uiSettings.language', 'EN').toLowerCase()
        this.$i18n.locale = lang
      },
      immediate: true,
    },
  },
  apollo: {
    profile: {
      query: PROFILE_QUERY,
    },
  },
}
</script>
