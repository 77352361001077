var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('NavigationStyled', _vm._l(_vm.navigationItems, function (navigationItem, i) {
    return _c('MainNavigationItem', {
      key: i,
      attrs: {
        "navigationItem": navigationItem
      }
    });
  }), 1);
}
var staticRenderFns = []

export { render, staticRenderFns }