<template>
  <NavigationStyled>
    <MainNavigationItem v-for="(navigationItem, i) in navigationItems" :key="i" :navigationItem="navigationItem" />
  </NavigationStyled>
</template>

<script>
import { styled } from '@egoist/vue-emotion'
import chroma from 'chroma-js'

import { getRolesFromToken } from '@common/utils'

import MainNavigationItem from './Navigation/MainNavigationItem'

const NavigationStyled = styled('nav')`
  grid-area: navigation;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  backdrop-filter: blur(7px);
  background: ${props => chroma(props.theme.colors.midnightBlue).darken(1.0).alpha(0.5).css()};
  max-height: 100%;
  overflow: auto;
`

export default {
  components: {
    NavigationStyled,
    MainNavigationItem,
  },
  computed: {
    routes() {
      return this.$router?.options?.routes || []
    },
    navigationItems() {
      const roles = getRolesFromToken(this.$keycloak.token)
      return this.routes
        .filter(route => {
          const requiresRoles = route.meta?.rolesAllowed
          const requiresRolesAndHasRole =
            (requiresRoles &&
              roles.some(roleInToken => {
                return route.meta.rolesAllowed.includes(roleInToken)
              })) ||
            !requiresRoles
          return route.meta?.isNavigationItem && requiresRolesAndHasRole
        })
        .map(route => ({
          ...route,
          name: route.name ?? route.meta.name,
        }))
    },
  },
}
</script>
