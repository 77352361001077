export default function (number, thousandsSeperator = '.', decimalSeperator = ',') {
  const parts = number.toString().split('.')
  parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, thousandsSeperator)
  if (parts[1] && parts[1].length === 1) {
    parts[1] = parts[1] + '0'
  }
  return parts.join(decimalSeperator)
}

export function parseLocaleFloat(number, mesureSystem) {
  if (!isNaN(number)) {
    return number
  }
  if (mesureSystem === 'METRIC') {
    const thousands = number.replace('.', '')
    const tenths = thousands.replace(',', '.')
    return parseFloat(tenths)
  }
  const thousands = number.replace(',', '')
  return parseFloat(thousands)
}
