var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('SubNavigationItemStyled', [_c('router-link', {
    attrs: {
      "to": {
        name: _vm.routeName
      }
    }
  }, [_c('span', [_vm._v(" " + _vm._s(_vm.$t("navigation.archon.".concat(_vm.routeName))) + " ")])])], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }