var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('MainNavigationItemStyled', [_c('router-link', {
    class: {
      'active-by-path': _vm.activeByPath
    },
    attrs: {
      "to": _vm.to,
      "exact": _vm.navigationItem.name === 'home'
    }
  }, [[_vm.navigationItem.name === 'home' ? _c('HomeIcon') : _vm.navigationItem.name === 'users' ? _c('UsersIcon') : _vm.navigationItem.name === 'assets' ? _c('TalpaIcon', {
    staticClass: "icon",
    attrs: {
      "scope": 'Misc',
      "name": 'FleetIcon'
    }
  }) : _vm.navigationItem.name === 'loggers' ? _c('HardDriveIcon') : _vm.navigationItem.name === 'assetDimensions' ? _c('ColumnsIcon') : _vm.navigationItem.name === 'organizations' ? _c('BriefcaseIcon') : _vm.navigationItem.name === 'sites' ? _c('MapIcon') : _vm.navigationItem.name === 'dashboards' ? _c('TrendingUpIcon') : _vm.navigationItem.name === 'calendars' ? _c('CalendarIcon') : _vm.navigationItem.name === 'events' ? _c('ZapIcon') : _vm.navigationItem.name === 'Marketplace' ? _c('ShoppingBagIcon') : _vm._e()], _c('span', [_vm._v(" " + _vm._s(_vm.$t("navigation.archon.".concat(_vm.navigationItem.name))) + " ")])], 2), _vm.subNavigationItems.length > 0 ? _c('SubNavigationItemsStyled', _vm._l(_vm.subNavigationItems, function (subNavigationItem, i) {
    return _c('SubNavigationItem', {
      key: i,
      attrs: {
        "navigationItem": subNavigationItem
      }
    });
  }), 1) : _vm._e()], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }