import DefaultHeader from '@/components/App/Header'
import { getRolesFromToken } from '@common/utils'
import get from 'lodash/get'
import Vue from 'vue'
import Router from 'vue-router'

Vue.use(Router)

function createRouter() {
  const router = new Router({
    mode: 'history',
    base: process.env.BASE_URL,
    routes: [
      {
        path: '/unauthorized',
        name: 'unauthorized',
        components: {
          header: DefaultHeader,
          main: () => import('@/components/misc/Unauthorized.vue'),
        },
        props: {
          header: {
            emptyTitle: true,
          },
        },
      },
      {
        path: '/',
        name: 'home',
        components: {
          header: DefaultHeader,
          main: () => import('@/components/Home/Main.vue'),
        },
        meta: {
          isNavigationItem: true,
        },
      },
      {
        path: '/users',
        components: {
          header: DefaultHeader,
          main: () => import('@/pages/UsersRoute.vue'),
        },
        meta: {
          name: 'users',
          isNavigationItem: true,
          rolesAllowed: ['archon', 'talpa-product'],
        },
        children: [
          {
            path: '/',
            name: 'users',
            component: () => import('@/pages/UsersRoute/ListUsersPage.vue'),
            meta: {
              rolesAllowed: ['archon', 'talpa-product'],
            },
          },
          {
            path: 'create',
            name: 'createUser',
            component: () => import('@/pages/UsersRoute/CreateUserPage.vue'),
            meta: {
              rolesAllowed: ['archon', 'talpa-product'],
            },
          },
          {
            path: ':id',
            name: 'user',
            component: () => import('@/pages/UsersRoute/EditUserPage.vue'),
            meta: {
              rolesAllowed: ['archon', 'talpa-product'],
            },
          },
          {
            path: '/duplicateuser/:id',
            name: 'duplicateUser',
            component: () => import('@/pages/UsersRoute/DuplicateUserPage.vue'),
            meta: {
              rolesAllowed: ['archon', 'talpa-product'],
            },
          },
        ],
      },
      {
        path: '/assets',
        name: 'assets',
        components: {
          header: DefaultHeader,
          main: () => import('@/components/Assets/AssetsMain.vue'),
        },
        meta: {
          isNavigationItem: true,
        },
        children: [
          {
            path: 'heavy-equipment',
            name: 'heavy-equipment',
            components: {
              header: DefaultHeader,
              main: () => import('@/components/Assets/ListAssets.vue'),
            },
            meta: {
              isNavigationItem: true,
            },
            children: [
              {
                path: 'create',
                name: 'createAsset',
                component: () => import('@/components/Assets/CreateAsset.vue'),
                meta: {
                  rolesAllowed: ['archon', 'talpa-product'],
                },
              },
              {
                path: ':id',
                name: 'asset',
                component: () => import('@/components/Assets/EditAsset.vue'),
                meta: {
                  rolesAllowed: ['archon', 'talpa-product'],
                },
              },
              {
                path: 'duplicate/:id',
                name: 'duplicateAsset',
                component: () => import('@/components/Assets/DuplicateAsset.vue'),
                meta: {
                  rolesAllowed: ['archon', 'talpa-product'],
                },
              },
            ],
          },
          {
            path: 'wheels',
            name: 'wheels',
            components: {
              header: DefaultHeader,
              main: () => import('@/components/Wheels/ListWheels.vue'),
            },
            meta: {
              isNavigationItem: true,
            },
            children: [
              {
                path: 'create',
                name: 'createWheel',
                component: () => import('@/components/Wheels/CreateWheel.vue'),
                meta: {
                  rolesAllowed: ['archon', 'talpa-product'],
                },
              },
              {
                path: ':id',
                name: 'wheel',
                component: () => import('@/components/Wheels/EditWheel.vue'),
                meta: {
                  rolesAllowed: ['archon', 'talpa-product'],
                },
              },
            ],
          },
          {
            path: 'tyres',
            name: 'tyres',
            components: {
              header: DefaultHeader,
              main: () => import('@/components/Tyres/ListTyres.vue'),
            },
            meta: {
              isNavigationItem: true,
            },
            children: [
              {
                path: 'create',
                name: 'createTyre',
                component: () => import('@/components/Tyres/CreateTyre.vue'),
                meta: {
                  rolesAllowed: ['archon', 'talpa-product'],
                },
              },
              {
                path: ':id',
                name: 'tyre',
                component: () => import('@/components/Tyres/EditTyre.vue'),
                meta: {
                  rolesAllowed: ['archon', 'talpa-product'],
                },
              },
            ],
          },
          {
            path: 'assetTypes',
            name: 'assetTypes',
            components: {
              header: DefaultHeader,
              main: () => import('@/components/Assets/AssetTypes/ListAssetTypes.vue'),
            },
            meta: {
              isNavigationItem: true,
            },
            children: [
              {
                path: 'create',
                name: 'createAssetType',
                component: () => import('@/components/Assets/AssetTypes/CreateAssetType.vue'),
                meta: {
                  rolesAllowed: ['archon', 'talpa-product'],
                },
              },
              {
                path: ':id',
                name: 'editAssetType',
                component: () => import('@/components/Assets/AssetTypes/EditAssetType.vue'),
                meta: {
                  rolesAllowed: ['archon', 'talpa-product'],
                },
              },
            ],
          },
          {
            path: 'assetUtilizationCategories',
            name: 'assetUtilizationCategories',
            components: {
              header: DefaultHeader,
              main: () => import('@/components/Assets/AssetUtilizationCategories/ListAssetUtilizationCategories.vue'),
            },
            meta: {
              isNavigationItem: true,
            },
            children: [
              {
                path: 'create',
                name: 'createAssetUtilizationCategory',
                component: () => import('@/components/Assets/AssetUtilizationCategories/CreateAssetUtilizationCategory.vue'),
                meta: {
                  rolesAllowed: ['archon', 'talpa-product'],
                },
              },
              {
                path: ':id',
                name: 'editAssetUtilizationCategory',
                component: () => import('@/components/Assets/AssetUtilizationCategories/EditAssetUtilizationCategory.vue'),
                meta: {
                  rolesAllowed: ['archon', 'talpa-product'],
                },
              },
            ],
          },
        ],
      },
      {
        path: '/loggers',
        name: 'loggers',
        components: {
          header: DefaultHeader,
          main: () => import('@/components/Loggers/ListLoggers.vue'),
        },
        meta: {
          isNavigationItem: true,
        },
        children: [
          {
            path: 'create',
            name: 'createLogger',
            component: () => import('@/components/Loggers/CreateLogger.vue'),
            meta: {
              rolesAllowed: ['archon', 'talpa-product'],
            },
          },
          {
            path: ':id',
            name: 'logger',
            component: () => import('@/components/Loggers/EditLogger.vue'),
            meta: {
              rolesAllowed: ['archon', 'talpa-product'],
            },
          },
        ],
      },
      {
        path: '/asset-dimensions',
        name: 'assetDimensions',
        components: {
          header: DefaultHeader,
          main: () => import('@/components/AssetDimensions/ListAssetDimensions.vue'),
        },
        meta: {
          isNavigationItem: true,
        },
        children: [
          {
            path: 'create',
            name: 'createAssetDimension',
            component: () => import('@/components/AssetDimensions/CreateAssetDimension.vue'),
            meta: {
              rolesAllowed: ['archon', 'talpa-product'],
            },
          },
          {
            path: ':id',
            name: 'assetDimension',
            component: () => import('@/components/AssetDimensions/EditAssetDimension.vue'),
            meta: {
              rolesAllowed: ['archon', 'talpa-product'],
            },
          },
        ],
      },
      {
        path: '/organizations',
        name: 'organizations',
        components: {
          header: DefaultHeader,
          main: () => import('@/components/Organizations/ListOrganizations.vue'),
        },
        meta: {
          isNavigationItem: true,
        },
        children: [
          {
            path: 'create',
            name: 'createOrganization',
            component: () => import('@/components/Organizations/CreateOrganization.vue'),
            meta: {
              rolesAllowed: ['archon', 'talpa-product'],
            },
          },
          {
            path: ':id',
            name: 'organization',
            component: () => import('@/components/Organizations/EditOrganization.vue'),
            meta: {
              rolesAllowed: ['archon', 'talpa-product'],
            },
          },
        ],
      },
      {
        path: '/subsidiary/:id',
        name: 'subsidiary',
        components: {
          header: DefaultHeader,
          main: () => import('@/components/Subsidiaries/Subsidiary.vue'),
        },
      },
      {
        path: '/sites',
        name: 'sites',
        components: {
          header: DefaultHeader,
          main: () => import('@/components/Sites/ListSites.vue'),
        },
        meta: {
          isNavigationItem: true,
        },
        children: [
          {
            path: 'create',
            name: 'createSite',
            component: () => import('@/components/Sites/CreateSite.vue'),
            meta: {
              rolesAllowed: ['archon', 'talpa-product'],
            },
          },
          {
            path: ':id',
            name: 'site',
            component: () => import('@/components/Sites/EditSite.vue'),
            meta: {
              rolesAllowed: ['archon', 'talpa-product'],
            },
          },
        ],
      },
      {
        path: '/dashboards',
        name: 'dashboards',
        components: {
          header: DefaultHeader,
          main: () => import('@/components/Dashboards/ListDashboards.vue'),
        },
        meta: {
          rolesAllowed: ['archon', 'talpa-product'],
        },
        children: [
          // { Commenting it out for enabling it in future
          //   path: 'create',
          //   name: 'createDashboard',
          //   component: () => import('@/components/Dashboards/CreateDashboard.vue'),
          //   meta: {
          //     rolesAllowed: ['archon', 'talpa-product'],
          //   },
          // },
          {
            path: ':id',
            name: 'dashboard',
            component: () => import('@/components/Dashboards/EditDashboard.vue'),
            meta: {
              rolesAllowed: ['archon', 'talpa-product'],
            },
          },
        ],
      },
      {
        path: '/calendars',
        name: 'calendars',
        components: {
          header: DefaultHeader,
          main: () => import('@/components/Calendars/CalendarsMain.vue'),
        },
        meta: {
          isNavigationItem: true,
        },
        children: [
          {
            path: 'calendars',
            name: 'listCalendars',
            components: {
              header: DefaultHeader,
              main: () => import('@/components/Calendars/ListCalendars.vue'),
            },
            meta: {
              isNavigationItem: true,
            },
            children: [
              {
                path: 'create',
                name: 'createCalendar',
                component: () => import('@/components/Calendars/CreateCalendar.vue'),
                meta: {
                  rolesAllowed: ['archon', 'talpa-product'],
                },
              },
              {
                path: ':id',
                name: 'calendar',
                component: () => import('@/components/Calendars/EditCalendar.vue'),
                meta: {
                  rolesAllowed: ['archon', 'talpa-product'],
                },
              },
            ],
          },
          {
            path: 'schedules',
            name: 'ListSchedules',
            components: {
              header: DefaultHeader,
              main: () => import('@/components/Calendars/ListSchedules.vue'),
            },
            meta: {
              isNavigationItem: true,
              rolesAllowed: ['archon', 'talpa-product'],
            },
            children: [
              {
                path: ':id',
                name: 'EditSchedule',
                component: () => import('@/components/Calendars/EditSchedule.vue'),
                meta: {
                  rolesAllowed: ['archon', 'talpa-product'],
                },
              },
            ],
          },
          {
            path: 'jobs',
            components: {
              header: DefaultHeader,
              main: () => import('@/components/Calendars/JobsMain.vue'),
            },
            meta: {
              isNavigationItem: true,
              name: 'jobs',
              rolesAllowed: ['archon', 'talpa-product'],
            },
            children: [
              {
                path: '',
                name: 'jobs',
                component: () => import('@/components/Calendars/ListJobs.vue'),
                meta: {
                  rolesAllowed: ['archon', 'talpa-product'],
                },

                props: route => {
                  return {
                    jobType: route.query.jobType || 'PLANNED',
                  }
                },
              },
              {
                path: 'reschedule',
                name: 'RescheduleJobs',
                component: () => import('@/components/Calendars/RescheduleJobs.vue'),
                meta: {
                  rolesAllowed: ['archon', 'talpa-product'],
                },
              },
              {
                path: ':id',
                name: 'job',
                component: () => import('@/components/Calendars/EditJob.vue'),
                meta: {
                  rolesAllowed: ['archon', 'talpa-product'],
                },
              },
            ],
          },
        ],
      },
      {
        path: '/events',
        components: {
          header: DefaultHeader,
          main: () => import('@/components/Events/EventsPage.vue'),
        },
        meta: {
          name: 'events',
          isNavigationItem: true,
          rolesAllowed: ['archon', 'talpa-product'],
        },
        children: [
          {
            path: 'list',
            name: 'events',
            component: () => import('@/components/Events/ListEvents.vue'),
            meta: {
              isNavigationItem: true,
              rolesAllowed: ['archon', 'talpa-product'],
            },
          },
          {
            path: 'shift-reports',
            name: 'shiftReports',
            component: () => import('@/components/Reports/ShiftReports.vue'),
            meta: {
              isNavigationItem: true,
            },
          },
          {
            path: ':id',
            name: 'event',
            component: () => import('@/components/Events/EditEvent.vue'),
            meta: {
              rolesAllowed: ['archon', 'talpa-product'],
            },
          },
        ],
      },
      {
        path: '/marketplace',
        name: 'Marketplace',
        components: {
          header: DefaultHeader,
          main: () => import('@/pages/MarketplaceRoute.vue'),
        },
        meta: {
          isNavigationItem: true,
        },
        children: [
          {
            path: 'products',
            name: 'ListProducts',
            component: () => import('@/pages/MarketplaceRoute/ListProductsPage.vue'),
            meta: {
              isNavigationItem: true,
              rolesAllowed: ['archon', 'talpa-product'],
            },
          },
          {
            path: 'products/create',
            name: 'CreateProduct',
            component: () => import('@/pages/MarketplaceRoute/CreateProductPage.vue'),
            meta: {
              // isNavigationItem: true,
              rolesAllowed: ['archon', 'talpa-product'],
            },
          },
          {
            path: 'products/:productId',
            component: () => import('@/pages/MarketplaceRoute/EditProductPage.vue'),
            meta: {
              rolesAllowed: ['archon', 'talpa-product'],
            },
            children: [
              {
                path: '',
                name: 'EditProduct',
                meta: {
                  translationKey: 'EditProduct',
                },
                component: () => import('@/pages/MarketplaceRoute/Templates/ProductOverviewTemplate.vue'),
              },
              {
                path: 'tier-levels',
                name: 'EditProductTierLevels',
                meta: {
                  translationKey: 'EditProduct',
                },
                component: () => import('@/pages/MarketplaceRoute/Organisms/ProductTierLevelsOrganism.vue'),
              },
              {
                path: 'acquisitions',
                name: 'EditProductAcquisitions',
                meta: {
                  translationKey: 'EditProduct',
                },
                component: () => import('@/pages/MarketplaceRoute/Organisms/ProductAcquisitionsOrganism.vue'),
              },
            ],
          },
          {
            path: 'acquisitions',
            name: 'acquisitions',
            component: () => import('@/pages/Acquisition.vue'),
            meta: {
              isNavigationItem: true,
              rolesAllowed: ['archon', 'talpa-product'],
            },
            children: [
              {
                path: 'create',
                name: 'createAcquisition',
                component: () => import('@/pages/Acquisition/AcquisitionCreate.vue'),
                meta: {
                  rolesAllowed: ['archon', 'talpa-product'],
                },
              },

              {
                path: ':id',
                name: 'acquisition',
                component: () => import('@/pages/Acquisition/AcquisitionEdit.vue'),
                meta: {
                  rolesAllowed: ['archon', 'talpa-product'],
                },
              },
            ],
          },
          {
            path: 'acquisitions/organization/:id',
            name: 'OrganizationAcquisitions',
            component: () => import('@/pages/Acquisition/OrganizationAcquisitionsPage.vue'),
            meta: {
              rolesAllowed: ['archon', 'talpa-product'],
            },
          },
        ],
      },
    ],
  })

  router.beforeEach(async (to, from, next) => {
    const nearestWithTitle = to.matched
      .slice()
      .reverse()
      .find(r => r.meta && r.meta.title)
    let appName = 'Archon'
    let poweredBy = 'powered by talpasolutions.com'
    let pageTitle = `${appName} - ${poweredBy}`
    if (nearestWithTitle) pageTitle = `${nearestWithTitle.meta.title} - ${appName} - ${poweredBy}`
    document.title = pageTitle
    const rolesAllowed = get(to, 'meta.rolesAllowed', [])
    if (rolesAllowed.length > 0) {
      const roles = getRolesFromToken(Vue.prototype.$keycloak.token)
      const userHasOneOfRoles = rolesAllowed.some(roleAllowed => {
        return roles.find(role => role === roleAllowed)
      })
      if (userHasOneOfRoles) {
        next()
      } else {
        next({ name: 'unauthorized' })
      }
    } else {
      next()
    }
  })

  return router
}

export default createRouter
