<template>
  <SettingsStyled @click="activateSettingsOverlay" :class="{ active: isActive }">
    <SettingsIcon />
  </SettingsStyled>
</template>

<script>
import { styled } from '@egoist/vue-emotion'
import chroma from 'chroma-js'
import { flexCenter, buttonReset } from '@styles/mixins'

import { SettingsIcon } from 'vue-feather-icons'

const SettingsStyled = styled('button')`
  ${flexCenter}
  grid-area: settings;
  outline: none;
  ${p => buttonReset(p)}
  background: ${props => chroma(props.theme.colors.midnightBlue).darken(1.0).alpha(0.5).css()};
  &:hover,
  &.active {
    background: ${props => chroma(props.theme.colors.midnightBlue).darken(1.0).alpha(0.8).css()};
  }
`

export default {
  props: {
    isActive: {
      type: Boolean,
      required: true,
    },
  },
  components: {
    SettingsStyled,
    SettingsIcon,
  },
  methods: {
    activateSettingsOverlay() {
      this.$emit('activateOverlay', 'SettingsOverlay')
    },
  },
}
</script>
