var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('AppStyled', [_c('GlobalStyle'), _c('AppHeader', [_c('Brand'), _c('ButtonStyled', {
    on: {
      "click": _vm.signOut
    }
  }, [_vm._v(_vm._s(_vm.$t('actions.logout')))])], 1), _c('UnauthorizedMessage', [_vm._v(" " + _vm._s(_vm.$t('messages.unauthorized')) + " ")])], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }