import { createProvider } from '@common/apollo'
import { de, en, ru } from '@common/i18n'
import { hasAnyTalpaRoleInToken } from '@common/utils/src/token'
import { VueEmotion } from '@egoist/vue-emotion'
import Keycloak from 'keycloak-js'
import Vue from 'vue'
import VueApollo from 'vue-apollo'
import VueFlashMessage from 'vue-flash-message'
import VueI18n from 'vue-i18n'
import VueLocalStorage from 'vue-localstorage'
import VueMeta from 'vue-meta'
import Vuelidate from 'vuelidate'
import App from './App.vue'
import UnauthorizedApp from './UnauthorizedApp.vue'
import createRouter from './router'
import { DateTime } from 'luxon'
import '@rschedule/luxon-date-adapter/v2/setup'

// VTooltip.options.defaultClass = 'my-tooltip'
Vue.config.productionTip = false

Vue.use(VueApollo)
Vue.use(VueMeta)
Vue.use(VueI18n)
Vue.use(VueLocalStorage)
Vue.use(VueEmotion)
Vue.use(Vuelidate)
Vue.use(VueFlashMessage)

const i18n = new VueI18n({
  messages: {
    en,
    de,
    ru,
  },
  locale: 'de',
})

Vue.filter('capitalize', function (value) {
  if (!value) {
    return ''
  }
  value = value.toString()
  return value.charAt(0).toUpperCase() + value.slice(1)
})

function formatDateTime(dt, options) {
  if (options) {
    return dt.toLocaleString(options)
  }
  return dt.toLocaleString(DateTime.DATETIME_MED_WITH_SECONDS)
}

Vue.filter('formatDateTime', function (value, options) {
  if (DateTime.isDateTime(value)) {
    return formatDateTime(value, options)
  }
  const dt = DateTime.fromISO(value)
  if (dt.isValid) {
    return formatDateTime(dt, options)
  }
  return value
})

export async function createApp(ssr = false) {
  // eslint-disable-next-line no-undef
  const keycloakVariables = appConfig?.keycloak
  const url = keycloakVariables?.url ?? ''
  const realm = keycloakVariables?.realm || 'talpa-default'
  const keycloak = new Keycloak({
    url: url + 'auth/',
    realm,
    clientId: keycloakVariables?.clientId || 'vanguard',
  })
  keycloak.onTokenExpired = () => {
    keycloak.updateToken()
  }

  const isAuthenticated = await keycloak.init({
    onLoad: 'login-required',
    enableLogging: process.env.NODE_ENVIRONMENT === 'development',
  })
  if (!isAuthenticated) {
    throw new Error('not authenticated')
  }

  Vue.prototype.$keycloak = keycloak
  const apolloProvider = createProvider(ssr, keycloak)
  const router = createRouter(apolloProvider, keycloak)

  const isAuthorized = hasAnyTalpaRoleInToken(keycloak.token)

  if (!isAuthorized) {
    const app = new Vue({
      apolloProvider,
      i18n,
      render: h => h(UnauthorizedApp),
    })

    return {
      app,
    }
  }

  const app = new Vue({
    apolloProvider,
    router,
    i18n,
    render: h => h(App),
  })
  return { app }
}
